<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <div v-if="!showLoading">
    <CRow style="min height:30px; margin-bottom:15px">
      <CCol>Perhatian: data pribadi anggota hanya boleh digunakan untuk keperluan pemuridan di Sion Academy.</CCol>
    </CRow>
    <CRow style="height:30px">
      <CCol>Total {{items.length}} anggota</CCol>
    </CRow>
    <CRow>
      <CDataTable
        :items="items"
        :fields="fields"
        table-filter
        :items-per-page="30"
        hover
        sorter
        pagination>
        <template #status="{item}">
          <td>
            <CBadge :color="getBadge(item.role)">
              {{item.status}}
            </CBadge>
          </td>
        </template>
        <template #show_details="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="onClickEdit(item, index)">
              Edit
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CRow>
  </div>

  <CModal :show.sync="showDialogEdit" title="Update data pribadi" hide-footer>
    <div style="padding: 10px">
      <CInput
        label="Nama"
        v-model="record.name"
        horizontal
      />
      <CInput
        label="Email"
        v-model="record.email"
        horizontal
      />
      <CInput
        label="No HP"
        v-model="record.mobile"
        horizontal
      />
      <CInput
        label="Kampus"
        v-model="record.campus"
        horizontal
      />
      <CInput
        label="Jurusan"
        v-model="record.major"
        horizontal
      />
      <CInput
        label="Angkatan"
        v-model="record.yearIn"
        horizontal
      />
      <CInput
        label="Pembimbing"
        v-model="record.mentor"
        horizontal
      />
      <CInput
        label="Kota domisili"
        v-model="record.city"
        horizontal
      />
      <CTextarea
        label="Catatan"
        v-model="record.note"
        horizontal
        rows="3"
      />
      <CSelect
        id="selectAccess"
        :options="accessOptions"
        :disabled="isDisableChangeAccess"
      />
    </div>

    <div slot="footer">
      <CButton @click="dialogEditClose()">Batal</CButton>
      <CButton @click="dialogEditSave()" :disabled="disableButtonOk">Simpan</CButton>
    </div>
  </CModal>

</div>
</template>

<script>
  import api from "../../api/api"

  const fields = [
    { key: 'name', label: 'Nama', _style:'min-width:150px' },
    { key: 'campus', label: 'Kampus', _style:'min-width:150px' },
    { key: 'yearIn', label: 'Angkatan', _style:'min-width:100px;' },
    { key: 'mentor', label: 'Pembimbing', _style:'min-width:100px;' },
    { key: 'access', label: 'Akses', _style:'min-width:50px;' },
    { key: 'validated', label: 'Validated', _style:'min-width:40px;' },
    { 
      key: 'show_details', 
      label: '', 
      _style: 'width:1%', 
      sorter: false, 
      filter: false
    }
  ]

  export default {
    name: 'Member',
    data () {
      return {
        showLoading: false,
        items: [],
        fields,
        details: [],

        accessOptions: ["ADMIN", "PEMBIMBING", "USER"],
        showDialogEdit: false,
        record: '',
        user: '',
        isDisableChangeAccess: true
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        if(this.user.access==="ADMIN") this.isDisableChangeAccess = false
        this.listUser()
      }
    },

    methods: {
      async listUser () {
        this.showLoading = true
        this.items = []
        let apiRes = await api.listUser()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.items = apiRes.users
          for(var i=0; i<this.items.length; i++){
            if(this.items[i].access==="LEADER") this.items[i].access="PEMBIMBING"
          }
        } 
        else {
          console.log("listUser ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      getBadge (status) {
        switch (status) {
          case 'ADMIN': return 'success'
          default: 'primary'
        }
      },

      onClickEdit (item) {
        this.record = item
        if(item.access === "ADMIN")
          document.getElementById("selectAccess").selectedIndex = 0
        else if(item.access === "PEMBIMBING")
          document.getElementById("selectAccess").selectedIndex = 1
        else 
          document.getElementById("selectAccess").selectedIndex = 2
        this.showDialogEdit = true
      },

      dialogEditClose () {
        this.showDialogEdit = false
      },

      async dialogEditSave () {
        var e1 = document.getElementById("selectAccess")

        if(this.record.note===undefined) this.record.note = " "

        switch(e1.selectedIndex) {
          case 0: this.record.access = "ADMIN"; break;
          case 1: this.record.access = "LEADER"; break;
          case 2: this.record.access = "USER"; break;
        }
        this.showDialogEdit = false

        this.showLoading = true
        let apiRes = await api.updateUser(this.user.id, this.record)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Update OK!")
          this.listUser()
        } 
        else {
          console.log("dialogEditSave ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },
    },

    computed: {
      disableButtonOk () {
        return this.record.note && this.record.note.length===0
      }
    },
  }
</script>
